import axios from "axios";

// TODO Change this to use env variables

const API = axios.create({
  baseURL:
    typeof window !== "undefined" &&
    window.location.origin.includes("localhost")
      ? "http://localhost:3000"
      : "https://api.react.school",
  withCredentials: true,
  headers: {
    common: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  },
});

export default API;
